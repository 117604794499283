<script>
    import { onMount } from 'svelte';
    import muxjs from 'mux.js';
    window.muxjs = muxjs;
    import shaka from 'shaka-player';
    shaka.polyfill.installAll();

    let manifestUri = "https://chunks.eldoradio.de/video/eldoradio.m3u8";
    let video;
    let player;

    onMount(() => {
        player = new shaka.Player(video);
        // player.addEventListener(onError);
        player.load(manifestUri);
    });

</script>

<style>
    video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>

<video bind:this={video} crossorigin="anonymous" preload="none" autoplay controls></video>
